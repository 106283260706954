




































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
// import { getView } from '@/utils/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, getView } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import scheduleModule from '@/store/modules/scheduleModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { create, all } from 'mathjs';
import cellRender from './agGrid/CellRender.js';
// import CellRender from './CellRender.js';
import CustomHeader from './agGrid/header.js';
import EditableHeader from './agGrid/headerEditable.js';
import TagSelect from './agGrid/userTagCell.vue';
import DateSelect from './userDateCell.vue';
import UserSelect from './userSelectCell.vue';
import VueAutoCompleteTextArea from './agGrid/autoCompleteTextarea.vue';

const config = { };
const math = create(all, config);
// import 'vue-auto-complete-textarea/dist/vue-auto-complete-textarea.css';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    AgGridVue,
    cellRender,
    agColumnHeader1: CustomHeader,
    agColumnEdiable: EditableHeader,
    UserSelect,
    Multiselect,
    TasqStatsCard: () => getComponent('tasqs/TasqStatsCard'),
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    GroupAssetUpload: () => getView('GroupAssetUpload'),
    TagSelect,
    DateSelect,
    VueAutoCompleteTextArea,
  },
})
export default class Tasqs extends Vue {
   windowWidth = window.innerWidth;

   newColumnName = '';

   showUploadAssetPopup: any = false;

   newGroupName = '';

   showFunctionPopup = false;

   showModal = false;

  showCreateGroupModal =false;

  activeDeleteRowWellName= '';

  deleteRowPopup = false;

  selectedWellNames = [];

  hideAgGrid: any = false;

  availableWellNames = [];

  dataLoading = false;

   showColumnMenu = false;

  selectedTags: any = [];

  availableTags = ['Tag1', 'Tag2']

  getRowNodeId: any = null;

  gridApi: any = null;

  gridColumnApi: any = null;

  wellModalTop: any = 0;

   showWellMenu = false;

    context: any = null;

   newWellName = '';

   frameworkComponents: any = {};

   selectedWell: any = ''

   columnDefs: any = [

   ];

  selectedFunctionColumn: any = null;

   functionExpressionValue: any = '';

     defaultColDef = {
       initialWidth: 250,
       sortable: true,
       editable: true,
       resizable: true,
     };

    rowData:any = [

    ];

    cellClick(e) {
      console.log(e);
    }

    get isMobile() {
      return this.windowWidth < 769;
    }

    get functionOperators() {
      return ['-', '+', '/', '*'];
    }

    get functionSuggestions() {
      const data = this.columnDefs.filter((col) => col.colId.includes('Number')).map((c) => ({ text: `[${c.headerName}]`, id: `[${c.colId}]` }));
      return data;
    }

     mathExpression: any ='';

     async setFunctionColumn() {
       this.getValueParse(this.functionExpressionValue);
       this.rowData = this.rowData.map((r) => {
         r[this.selectedFunctionColumn.colId] = this.mathExpression;
         return r;
       });
       this.showFunctionPopup = false;
       await this.saveGroupDetailsToBackend()
     }

     getValueParse(expression) {
       let replaceString = expression;
       let replaceVal = expression;
       // ts-ignore
       // eslint-disable-next-line no-param-reassign
       replaceVal = replaceVal.match(/\[(.*?)\]/);

       while (replaceVal && replaceVal[0]) {
         // eslint-disable-next-line no-loop-func
         const r = this.columnDefs.filter((c) => c.headerName === replaceVal[1]);

         if (r && r[0]) {
           replaceString = replaceString.replace(replaceVal[0], `{${r[0].colId}}`);
         }

         replaceVal = replaceString.match(/\[(.*?)\]/);
       }
       this.mathExpression = replaceString;
     }

     parseFunctionValue(params) {
       try {
         if (params && params.value) {
           let replaceVal = params.value;
           let replaceString = replaceVal;
           // ts-ignore
           // eslint-disable-next-line no-param-reassign

           replaceVal = replaceVal.match(/\{(.*?)\}/);

           while (replaceVal && replaceVal[0]) {
             if (params.data[replaceVal[1]]) {
               replaceString = replaceString.replace(replaceVal[0], `${params.data[replaceVal[1]]}`);
             } else {
               replaceString = '-';
             }
             replaceVal = replaceString.match(/\{(.*?)\}/);
           }
           if (replaceString === '-' || !this.isValidMathExpression(replaceString)) {
             return '-';
           }
           const returnVal = math.evaluate(replaceString);
           return returnVal;
         }
         return '-';
       } catch (error) {
         return '-';
       }
     }

     isValidMathExpression(expr) {
       try {
         math.evaluate(expr);
         return true;
       } catch (ex) {
         return false;
       }
     }

     onCellKeyDown(e) {
       const keyPressed = e.event.key;
       const currentCell = this.gridApi.getFocusedCell();
       const finalRowIndex = this.gridApi.paginationGetRowCount() - 1;
       const allColumns = (this.gridColumnApi.getAllDisplayedColumns());
       // const firstCol = this.gridColumnApi.getAllDisplayedColumns()[1];

       if (currentCell.rowIndex === finalRowIndex) {
         return;
       }

       // this.gridApi.setFocusedCell(2, firstCol);
       if (keyPressed === 'ArrowRight') {
         const findIndex = allColumns.findIndex((e) => e.colId === currentCell.column.colId);
         if (allColumns[findIndex + 1] && allColumns[findIndex + 1].colId !== 'Add Column +') {
           if (allColumns[findIndex + 1].colDef.editable) {
             this.gridApi.setFocusedCell(currentCell.rowIndex, allColumns[findIndex + 1].colId);
             this.gridApi.startEditingCell({
               rowIndex: currentCell.rowIndex,
               colKey: allColumns[findIndex + 1].colId,
             });
           } else {
             this.gridApi.setFocusedCell(currentCell.rowIndex, allColumns[findIndex + 1].colId);
           }
         }
       } else if (keyPressed === 'ArrowDown') {
         this.gridApi.startEditingCell({
           rowIndex: currentCell.rowIndex + 1,
           colKey: currentCell.column.colId,
         });
         this.gridApi.setFocusedCell(currentCell.rowIndex + 1, currentCell.column.colId);
       } else if (keyPressed === 'ArrowUp') {
         this.gridApi.startEditingCell({
           rowIndex: currentCell.rowIndex - 1 ? currentCell.rowIndex - 1 : finalRowIndex,
           colKey: currentCell.column.colId,
         });
         this.gridApi.setFocusedCell(currentCell.rowIndex - 1, currentCell.column.colId);
       } else if (keyPressed === 'ArrowLeft') {
         const findIndex = allColumns.findIndex((e) => e.colId === currentCell.column.colId);
         if (allColumns[findIndex - 1] && allColumns[findIndex - 1].colId !== 'Well') {
           if (allColumns[findIndex - 1].colDef.editable) {
             this.gridApi.setFocusedCell(currentCell.rowIndex, allColumns[findIndex - 1].colId);
             this.gridApi.startEditingCell({
               rowIndex: currentCell.rowIndex,
               colKey: allColumns[findIndex - 1].colId,
             });
           } else {
             this.gridApi.setFocusedCell(currentCell.rowIndex, allColumns[findIndex - 1].colId);
           }
         }
       } else if (keyPressed === 'Enter') {
         this.gridApi.tabToNextCell();
       }
     }

     goToTasqPage(data) {
       const { colDef } = data;
       const wellName = data.value;
       if (colDef && colDef.field === 'Well') {
         const tasq = tasqsListModule.tasqList.find((t) => t.wellName === wellName);
         if (tasq && tasq.id) {
           tasqsListModule.setActiveTasq(tasq.id || '');
           tasqsListModule.setKanbanSelectedTasq(tasq?.id || '');
           this.showModal = true;
         } else {
           tasqsListModule.setKanbanSelectedTasq(wellName);
           assetsModule.setActiveProducingTasq(wellName);
           tasqsListModule.setActiveTasq('');
           this.showModal = true;
         }
       }
       // tasqsListModule.setActiveTasq(tasq?.id || '');
     }

     addTag(newTag) {
       if (newTag) {
         this.availableTags.push(newTag);
         this.selectedTags.push(newTag);
       }
     }

     async stopEdit() {
       await this.saveGroupDetailsToBackend();
     }

     toggleWellPopupModal(e) {
       // console.log(e);
       this.showWellMenu = !this.showWellMenu;
       this.wellModalTop = e.y;
     }

     closeDialog() {
       this.hideAgGrid = true;
       // console.log('close');
       this.showModal = false;
       setTimeout(() => {
         this.hideAgGrid = false;
       }, 300);
     }

     onGridReady(params) {
       this.gridApi = params.api;
       this.rowData = [];
       this.columnDefs = [];
       this.gridColumnApi = params.columnApi;
       const columns = [];
       this.gridApi.hideOverlay();

       if (this.activeGroup && this.activeGroup.Payload) {
         this.rowData = this.activeGroup.Payload;
       }

       if (this.activeGroup && this.activeGroup.Columns) {
         this.activeGroup.Columns.forEach((col) => {
           // console.log(col.ColumnType);
           const hideColumn = false;
           let newCol: any = null;
           // if(col.CustomName === ''){
           //   col.CustomName = 'Unnamed'

           console.log(col.ColumnType);
           // }
           if (col.ColumnType === 'WELL') {
             newCol = {
               field: 'Well',
               headerName: col.CustomName,
               cellClass: 'noborder',
               cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', borderRight: 'none' },
               sortable: true,
               colId: 'Well',
               pinned: 'left',
               filter: true,
               width: 300,
               editable: false,
               checkboxSelection: false,
             };
             this.columnDefs.push(newCol);
           } else if (col.ColumnType === 'TAGS') {
             newCol = {
               field: col.ColumnName,
               sortable: true,
               editable: hideColumn,
               headerComponent: 'agColumnEdiable',
               cellClass: 'noborder',
               cellStyle: {
                 borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
               },
               headerName: col.CustomName,
               suppressRowTransform: true,
               suppressToolPanel: true,
               colId: col.ColumnName,
               initialWidth: 350,
               cellRendererFramework: 'TagSelect',
             };
             this.columnDefs.push(newCol);
           } else if (col.ColumnType === 'DATE') {
             newCol = {
               field: col.ColumnName,
               sortable: true,

               headerComponent: 'agColumnEdiable',
               cellClass: 'noborder',
               cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible' },
               headerName: col.CustomName,
               suppressRowTransform: true,
               cellEditorPopup: true,
               editable: false,
               suppressToolPanel: true,

               colId: col.ColumnName,
               initialWidth: 150,
               cellRendererFramework: 'DateSelect',
             };
             this.columnDefs.push(newCol);
           } else if (col.ColumnType === 'NUMBERS') {
             newCol = {
               field: col.ColumnName,
               headerComponent: 'agColumnEdiable',
               colId: col.ColumnName,
               initialWidth: 150,
               cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)' },
               cellClass: 'noborder',
               headerName: col.CustomName,
               // valueParser: function numberParser(value) {
               //   // isNaN(value.newValue)
               //   return Number.isNaN(value.newValue) ? '' : Number(value.newValue);
               // },

               valueGetter(value) {
                 const key = col.ColumnName;
                 return value.data[key];
               },

               valueSetter: (value) => {
                 if (isNaN(value.newValue)) {
                   // alert('not valid number');
                   return false;
                 }
                 const key = col.ColumnName;
                 value.data[key] = value.newValue;
                 return true;
               },
               valueFormatter: (params) => {
                 if (params.value === '' || params.value === null || params.value === undefined || Number.isNaN(params.value)) {
                   return '-';
                 }
                 return params.value;
               },

               // valueParser:  function numberFormatter(value) {
               //   console.log(Number.isNaN(parseInt(value.newValue)))
               //   return isNaN(Number(value.value)) ? '': (Number(params.value))
               // },
             };
             this.columnDefs.push(newCol);
           } else if (col.ColumnType === 'FUNCTION') {
             newCol = {
               field: col.ColumnName,
               headerComponent: 'agColumnEdiable',
               colId: col.ColumnName,
               initialWidth: 150,
               cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)' },
               cellClass: 'noborder',
               headerName: col.CustomName,
                editable: false,
               // valueParser: function numberParser(value) {
               //   // isNaN(value.newValue)
               //   return Number.isNaN(value.newValue) ? '' : Number(value.newValue);
               // },

               valueGetter(value) {
                 const key = col.ColumnName;
                 return value.data[key];
               },

               valueFormatter: (params) => {
                 //  console.log(params)
                 if (params.value === '' || params.value === null || params.value === undefined) {
                   return '-';
                 }
                 const value = this.parseFunctionValue(params);
                 if (value === undefined || Number.isNaN(value)) {
                   return '-';
                 }
                 return value;
               },

               // valueParser:  function numberFormatter(value) {
               //   console.log(Number.isNaN(parseInt(value.newValue)))
               //   return isNaN(Number(value.value)) ? '': (Number(params.value))
               // },
             };
             this.columnDefs.push(newCol);
           } else {
             newCol = {
               field: col.ColumnName,
               headerComponent: 'agColumnEdiable',
               colId: col.ColumnName,
               cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible' },

               cellClass: 'noborder',
               headerName: col.CustomName,
               valueFormatter: (params) => {
                 // console.log(params.value);
                 if (params.value === '' || params.value === null || params.value === undefined) {
                   return '-';
                 }
               },
             };
             this.columnDefs.push(newCol);
           }
         });

         const endColumn = {
           field: 'Add Column +',
           sortable: true,
           pinned: 'right',
           colId: 'Add Column +',
           cellClass: 'noborder',
           suppressToolPanel: true,
           cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', borderLeft: '1px solid rgba(255, 255, 255, 0.1)' },
           headerComponent: 'agColumnHeader1',
           editable: false,
           minWidth: 120,
           initialWidth: 120,
           cellRendererFramework: 'cellRender',
           cellRendererParams: {
             clicked(field) {
               alert(`${field} was clicked`);
             },
           },
         };

         this.columnDefs.push(endColumn);
       }
     }

     createGroup() {
       this.selectedTags = [];
       this.selectedWellNames = [];
       this.newGroupName = '';
       this.showCreateGroupModal = false;
     }

     async confirmDeleteRow() {
       this.deleteRowPopup = false;
       this.rowData = this.rowData.filter((d) => d.Well !== this.activeDeleteRowWellName || !d.Well);
       await this.saveGroupDetailsToBackend();
       this.activeDeleteRowWellName = '';
     }

     removeRow(wellName) {
       this.activeDeleteRowWellName = wellName;
       this.deleteRowPopup = true;
     }

     async methodFromParent(name, type) {
       let count = 0;
       const isExist = this.columnDefs.filter((d) => d.field.includes(type));

       if (isExist) {
         count = isExist.length + 1;
       }
       const updatedName = `${name}${count}-id:${Math.random().toString(16).slice(2)}`;

       // console.log(updatedName);

       if (name && type && type === 'Tags') {
         const data = {
           field: name.toUpperCase(),
           sortable: true,
           editable: false,
           headerComponent: 'agColumnEdiable',
           cellClass: 'noborder',
           cellStyle: { border: 'none' },
           headerName: name,

           colId: name,
           initialWidth: 350,
           cellRendererFramework: 'TagSelect',
         };
         this.columnDefs.push(data);
       } else if (name && type && type === 'Date') {
         const data = {
           field: `${updatedName}`,
           sortable: true,
           editable: false,
           headerComponent: 'agColumnEdiable',
           cellClass: 'noborder',
           cellStyle: { border: 'none' },
           headerName: `${name}`,
           colId: `${updatedName}`,
           initialWidth: 160,
           cellRendererFramework: 'DateSelect',

         };

         this.columnDefs.push(data);
       } else if (name && type && type === 'Numbers') {
         this.columnDefs.push({
           field: `${updatedName}`,
           headerComponent: 'agColumnEdiable',
           cellStyle: { border: 'none' },
           colId: `${updatedName}`,
           initialWidth: 160,
           cellClass: 'noborder',
           headerName: name,
           valueSetter: (value) => {
             if (Number.isNaN(value.newValue)) {
               // alert('not valid number');
               return false;
             }
             const key = updatedName;
             // eslint-disable-next-line no-param-reassign
             value.data[key] = value.newValue;
             return true;
           },
           valueFormatter: (params) => {
             if (params.value === '' || params.value === null || params.value === undefined || Number.isNaN(params.value)) {
               return '-';
             }
             return params.value;
           },
         });
       } else if (name && type && type === 'Funtion') {
         this.columnDefs.push({
           field: `${updatedName}`,
           headerComponent: 'agColumnEdiable',
           cellStyle: { border: 'none' },
           colId: `${updatedName}`,
           initialWidth: 160,
            editable: false,
           cellClass: 'noborder',
           headerName: name,
           valueSetter: (value) => {
             if (Number.isNaN(value.newValue)) {
               // alert('not valid number');
               return false;
             }
             const key = updatedName;
             // eslint-disable-next-line no-param-reassign
             value.data[key] = value.newValue;
             return true;
           },

           valueFormatter: (params) => {
             //  console.log(params)
             if (params.value === '' || params.value === null || params.value === undefined) {
               return '-';
             }
             const value = this.parseFunctionValue(params);
             if (value === undefined || Number.isNaN(value)) {
               return '-';
             }
             return value;
           },
         });
       } else {
         this.columnDefs.push({
           field: `${updatedName}`,
           headerComponent: 'agColumnEdiable',
           cellStyle: { border: 'none' },
           colId: `${updatedName}`,
           initialWidth: 160,
           cellClass: 'noborder',
           headerName: name,
           valueFormatter: (params) => {
             if (params.value === '' || params.value === null || params.value === undefined || Number.isNaN(params.value)) {
               return '-';
             }
             return params.value;
           },
         });
       }

       this.activeGroup.Columns.push({
         ColumnName: `${updatedName}`,
         ColumnType: name.toUpperCase(),
         CustomName: name,
       });

       this.gridApi.hideOverlay();
       await this.saveGroupDetailsToBackend();
     }

     async saveGroupDetailsToBackend() {
       const updatedObject = {
         group_id: this.activeGroup.ID,
         columns: JSON.stringify(this.activeGroup.Columns),
         payload: JSON.stringify(this.rowData),
       };

       await scheduleModule.updateGroupDetails(updatedObject);
     }

     beforeMount() {
       this.context = { componentParent: this };
       // @ts-ignore
       window.gridApiVue = this;
     }

     closeColumnDialog() {
       this.showColumnMenu = false;
     }

     get assignedWells() {
       return this.rowData.map((element) => element.Well);
     }

     get enabledWells() {
       // console.log(this.assignedWells);
       // console.log(assetsModule.enabledWells);
       return assetsModule.enabledWells.filter((w) => !this.assignedWells.includes(w.wellName));
     }

     get activeGroup() {
       return scheduleModule.activeGroup;
     }

     async updateColumnName(oldColName, newColumnName, colId) {
       // console.log(oldColName);
       // console.log(this.activeGroup.Columns);
       this.activeGroup.Columns = this.activeGroup.Columns.map((c) => {
         if (c.CustomName === oldColName && c.ColumnName === colId) {
           c.CustomName = newColumnName;
         }
         return c;
       });

       this.columnDefs = this.columnDefs.map((c) => {
         if (c.headerName === oldColName && c.colId === colId) {
           c.headerName = newColumnName;
         }
         return c;
       });
       // console.log(this.activeGroup.Columns);
       await this.saveGroupDetailsToBackend();
     }

     async updateDataFromCell(index, fieldName, data) {
       this.rowData[index][fieldName] = data;
       // console.log( this.rowData[index][fieldName])
       await this.saveGroupDetailsToBackend();
     }

     async created() {
       this.dataLoading = true;
       this.getRowNodeId = (data) => data.Well;
       await assetsModule.getEnabledWells();
       // this.availableWellNames = assetsModule.enabledWells.map((w) => w.wellName);
       if (!this.activeGroup) {
         this.$router.push({ name: 'Groups' });
       }
       await this.sleep(500);
       this.dataLoading = false;
     }

     mounted() {
       this.$nextTick(() => {
         window.addEventListener('resize', this.onResize);
       });
     }

     beforeDestroy() {
       window.removeEventListener('resize', this.onResize);
     }

     showFunctionPoupUp(column) {
       this.functionExpressionValue = '';
       //  console.log(column);
       this.selectedFunctionColumn = column;
       //  console.log(this.rowData[0][column.colId])
       if (this.rowData && this.rowData[0] && this.rowData[0][column.colId]) {
         let replaceString = this.rowData[0][column.colId];
         let replaceVal = this.rowData[0][column.colId];
         // ts-ignore
         // eslint-disable-next-line no-param-reassign
         replaceVal = replaceVal.match(/\{(.*?)\}/);

         while (replaceVal && replaceVal[0]) {
         // eslint-disable-next-line no-loop-func
           const r = this.columnDefs.filter((c) => c.colId === replaceVal[1]);

           if (r && r[0]) {
             replaceString = replaceString.replace(replaceVal[0], `[${r[0].headerName}]`);
           }

           replaceVal = replaceString.match(/\{(.*?)\}/);
         }
         this.functionExpressionValue = replaceString;
       }
       this.showFunctionPopup = true;
     }

     async removeColumn(columnName, colId) {
       // console.log(columnName, colId)
       this.columnDefs = this.columnDefs.filter((d) => d.colId !== colId);
       this.activeGroup.Columns = this.activeGroup.Columns.filter((d) => d.ColumnName !== colId);
       await this.saveGroupDetailsToBackend();
       //  console.log(this.rowData)
     }

     sleep(ms) {
       return new Promise((resolve) => setTimeout(resolve, ms));
     }

     async addRow() {
       if (this.newWellName) {
         this.rowData.push({ Well: this.newWellName });
         this.newWellName = '';
       }
       await this.saveGroupDetailsToBackend();
     }

     onResize() {
       this.windowWidth = window.innerWidth;
     }

    @Watch('columnDefs')
     updateRowData(data) {
       if (data) {
         if (data.length > 1 && data[1].field === 'Add Column +' && data[1].pinned === 'left') {
           this.columnDefs[1].pinned = 'right';
         }
       }
     }
}

